import './vendors/h5bp';

/* 
 * Muestro todas las ubicaciones donde se exporta
 *
 * - Ejecucion en: $(document).ready()
 */
function goMarkets() {

  var markers = [
      ['<b>Alemania</b>', 51.068122, 9.829038],
      ['<b>Argelia</b>', 28.005289, 2.563659],
      ['<b>Arabia Saudita</b>', 23.577239, 45.250224],
      ['<b>Austria</b>', 47.541275, 14.134463],
      ['<b>Bélgica</b>', 50.472526, 4.837239],
      ['<b>Brasil</b>', -8.446573, -55.457922],
      ['<b>Canadá</b>', 60.189994, -111.619809],
      ['<b>Chile</b>', -26.847025, -70.206845],
      ['<b>Chipre</b>', 35.038131, 33.206068],
      ['<b>Colombia</b>', 3.582809, -73.427158],
      ['<b>Corea del Sur</b>', 36.558222, 127.808795],
      ['<b>Costa Rica</b>', 10.055920, -84.049864],
      ['<b>Dinamarca</b>', 55.555602, 10.055432],
      ['<b>Egipto</b>', 26.528690, 29.746997],
      ['<b>El Salvador</b>', 13.763105, -89.109600],
      ['<b>Emiratos Árabes Unidos</b>', 23.822513, 54.111595],
      ['<b>España</b>', 39.452581, -3.181373],
      ['<b>Estados Unidos</b>', 40.072291, -101.644041],
      ['<b>Filipinas</b>', 12.544274, 123.053103],
      ['<b>Francia</b>', 46.561413, 2.668780],
      ['<b>India</b>', 22.597832, 79.348807],
      ['<b>Indonesia</b>', -4.424817, 121.874327],
      ['<b>Irak</b>', 33.029578, 42.985588],
      ['<b>Irlanda</b>', 53.132909, -8.095511],
      ['<b>Israel</b>', 31.006876, 34.946505],
      ['<b>Italia</b>', 42.934600, 12.404992],
      ['<b>Japón</b>', 36.381168, 138.980833],
      ['<b>Jordania</b>', 31.316680, 36.631879],
      ['<b>Kuwait</b>', 29.318970, 47.479883],
      ['<b>Líbano</b>', 34.096228, 35.846447],
      ['<b>Libia</b>', 26.955011, 17.593035],
      ['<b>Lituania</b>', 55.565175, 23.750313],
      ['<b>Malasia</b>', 3.519054, 102.353785],
      ['<b>Marruecos</b>', 31.865192, -6.417818],
      ['<b>Mauricius</b>', -20.304973, 57.624092],
      ['<b>México</b>', 23.968344, -102.835786],
      ['<b>Panamá</b>', 9.101505, -79.404268],
      ['<b>Polonia</b>', 52.853581, 18.908840],
      ['<b>Reino Unido</b>', 54.956209, -2.562812],
      ['<b>Rumania</b>', 45.840934, 25.154758],
      ['<b>Rusia</b>', 62.066821, 93.223589],
      ['<b>Singapur</b>', 1.355914, 103.853870],
      ['<b>Siria</b>', 35.002627, 38.462048],
      ['<b>Sri Lanka</b>', 7.664424, 80.893642],
      ['<b>Sudáfrica</b>', -30.819341, 24.735776],
      ['<b>Suecia</b>', 64.533174, 16.334599],
      ['<b>Suiza</b>', 46.791262, 8.035084],
      ['<b>Tailandia</b>', 15.649950, 101.395380],
      ['<b>Trinidad y Tobago</b>', 10.367020, -61.258476],
      ['<b>Turkia</b>', 38.884359, 35.374526],
      ['<b>Ucrania</b>', 48.836183, 31.084487],
      ['<b>Uruguay</b>', -32.792843, -56.243142],
      ['<b>Venezuela</b>', 6.967450, -65.923723],
      ['<b>Vietnam</b>', 14.440551, 108.451519]      
  ];
  var latlng = new google.maps.LatLng(12.292571, -0.652166);
  var mapOptions = {
    zoom: 2,
    center: latlng,
    mapTypeControl: false,
    scaleControl: false,
    scrollwheel: false,
    panControl: false,
    rotateControl: false,
    zoomControl: false,
    streetViewControl: false,
    styles: [ { "featureType": "landscape", "stylers": [ { "color": "#ffffff" } ] },{ "featureType": "poi", "elementType": "geometry", "stylers": [ { "color": "#211915" } ] },{ "featureType": "water", "stylers": [ { "color": "##AEC6D0" } ] } ]
  };
  var map = new google.maps.Map(document.getElementById('market-canvas'), mapOptions);
  var infowindow = new google.maps.InfoWindow();
  var marker;
  var i;
  for (i = 0; i < markers.length; i++) {
    marker = new google.maps.Marker({
      position: new google.maps.LatLng(markers[i][1], markers[i][2]),
      map: map,
      icon: '../../assets/media/location-pin-s.png'
    });
    google.maps.event.addListener(marker, 'click', (function (marker, i) {
      return function () {
        infowindow.setContent(markers[i][0]);
        infowindow.open(map, marker);
      };
    })(marker, i));
  }
}


/* 
 * Mapa mediante la API de Google Maps
 */

function googleMaps() {
  
  // Ubicación LAT
  var lugar = new google.maps.LatLng(-31.767706, -60.450777);
  var centro = new google.maps.LatLng(-31.767706, -60.450777);

  // Estilos
  var estilos = [ { "featureType": "landscape", "stylers": [ { "color": "#ffffff" } ] },{ "featureType": "poi", "elementType": "geometry", "stylers": [ { "color": "#211915" } ] },{ "featureType": "water", "stylers": [ { "color": "#211915" } ] } ];

  // Opciones del mapa
  var mapOptions = {
    center: centro,
    zoom: 10,
    styles: estilos,
    scrollwheel: false
  };

  // Crear mapa
  var map = new google.maps.Map(document.getElementById("map-canvas"), mapOptions);

  // Marcador
  var marker = new google.maps.Marker({
    position: lugar,
    icon: "../../assets/media/location-pin.png",
    map: map,//lo asocio al mapa
    optimized: false,
    title: 'Naiman Foods'
  });

  var infoWindow = new google.maps.InfoWindow({
      content: "<p style='text-align:center'>Hernandarias y J. Ubach y Roca Parque Industrial <br> Paraná | Entre Ríos | Argentina</p>"
  });

  // google.maps.event.addListener(marker, 'click', function () {
      infoWindow.open(map, marker);
  // });

}


/* 
 * Ejecuto funciones de materialize
 *
 * - Ejecucion en: $(document).ready()
 */
function goMaterialize() {
  $(".button-collapse").sideNav();
}



/* 
 * Ejecuto funciones de visualización mediante fancybox
 *
 * - Ejecucion en: $(document).ready()
 */
function goFancy() {
  $(".fancybox").fancybox({
    padding: 0
  });
}


/* 
 * Envío del formulario de contacto
 *
 * - Ejecucion en: $(document).ready()
 */
function contactFormGo(){

  $("#contact-submit").on('click', function(e){//formulario de contacto
    e.preventDefault();

    var okflag=true;
    $('.contact-form input, .contact-form textarea').filter('[required]:visible').each(function(i, requiredField){
      if($(requiredField).val()==''){
        if(okflag==true)$('#msg-all').fadeIn().delay(5000).fadeOut();
        okflag=false; 
        $(requiredField).addClass("contact-error");
      }
      else $(requiredField).removeClass("contact-error");
    });

    if(okflag==true)
      $('#msg').addClass("backGload").fadeIn().load('contacto.php', {
        nombre: $('#contact-name').val(),
        empresa: $('#contact-bussines').val(), 
        telefono: $('#contact-phone').val(),    
        email: $('#contact-email').val(),
        mensaje: $('#contact-message').val()
      }, function(){
        $('input, textarea').val("");
        $('#msg').removeClass("backGload");
      });

  });

}


/*
 * 
 * Ejecuto el llamado de todas las funciones Nac y Pop
 *
 */
$(document).ready(function(){

  goMaterialize();
  new WOW().init();
  if($("#market-canvas").length){
    goMarkets();
  }
  if($("#map-canvas").length){
    googleMaps();
  }
  goFancy() ;
  contactFormGo();

});